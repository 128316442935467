@import 'alignment';

.list {
  font-size: 16px;
  line-height: 26px;
  &__wrapper {
    grid-column: 1 / 4;
    color: #333;
    font-family: 'Roboto Condensed', sans-serif;
    padding: 0px 0px;
    margin: 20px 0;
    header {
      text-align: center;
      padding: 15px 40px;
      color: #013831;
      .title,
      .subtitle {
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        margin: 0;
        padding: 0;
        color: #013831;
        &.no-content {
          color: rgba(#013831, 0.5);
        }
      }
      .title {
        font-size: 18px;
        line-height: 16px;
      }
      .subtitle {
        font-size: 10px;
        margin: 0;
        margin-top: 10px;
        padding: 0;
      }
      .title-field,
      .subtitle-field {
        width: 100%;
        input {
          padding: 0;
          text-align: center;
          text-transform: uppercase;
          font-family: 'Montserrat', sans-serif;
          font-weight: bold;
        }
      }
      .title-field {
        input {
          font-size: 18px;
          line-height: 16px;
        }
      }
      .subtitle-field {
        input {
          font-size: 10px;
        }
      }
    }
    ol,
    ul {
      padding: 0px 40px;
      margin: 0;
    }
    &.bulleted-list-fancy,
    &.numbered-list-fancy,
    &.bulleted-list-standard,
    &.numbered-list-standard {
      color: #033832;
      background: rgba(225, 225, 225, 0.5);
      header {
        background: #d7d7d7;
      }
      ol,
      ul {
        padding: 30px 40px;
        margin: 0 10px;
      }
    }
    &.bulleted-list-fancy,
    &.numbered-list-fancy {
      header {
        border-bottom: 2px solid #9acb42;
      }
      ol,
      ul {
        li {
          padding: 5px 10px;
          &::marker {
            color: #9acb42;
            font-weight: bold;
            font-size: 21px;
          }
          strong {
            display: block;
            font-size: 21px;
          }
        }
      }
    }
    &.bulleted-list-standard,
    &.numbered-list-standard {
      header {
        border-bottom: 2px solid #60c2a7;
      }
    }
  }
}
