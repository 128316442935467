& > h1,
& > h2,
& > h3,
& > h4,
& > h5 {
  font-family: 'Montserrat', sans-serif;
  color: var(--garnish-dark-green);
  font-weight: bold;
  text-transform: uppercase;
}

& > h1 {
  font-size: 35px;
  letter-spacing: 1.75;
}
& > h2 {
  font-size: 24px;
  letter-spacing: 1;
}
& > h3 {
  font-size: 18px;
  letter-spacing: 1;
}
& > h4 {
  font-size: 15px;
  letter-spacing: 1;
}
& > h5 {
  font-size: 12px;
  letter-spacing: 1;
}
