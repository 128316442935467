// These are custom block elements that expand
// when their title-bar is clicked

.expandable {
  grid-column: 1 / 4;
  margin: 10px 0;
  .title-bar {
    height: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 0 12px 0 15px;
    justify-content: flex-start;
    &:before {
      content: '';
      display: block;
      margin-right: 10px;
    }
    &:after {
      content: '';
      display: block;
      background: url('../../../images/expand-white.svg') center center
        no-repeat;
      height: 28px;
      width: 28px;
      margin-left: auto;
    }
    &:hover {
      cursor: pointer;
    }
    .title {
      margin: 0;
      letter-spacing: 0.5px;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
  .body {
    background: rgba(215, 215, 215, 0.5);
    padding: 0 20px;
    font-family: 'Roboto Condensed', sans-serif;
    section {
      padding: 20px 0;
      border-bottom: 1px solid #979797;
      &:last-of-type {
        border: none;
      }
    }
    h3 {
      letter-spacing: 0.5px;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      font-size: 16px;
      color: var(--garnish-dark-green);
      margin: 0 0 10px 0;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      color: var(--garnish-dark-green);
      font-style: italic;
    }
  }
  &.definition {
    .title-bar {
      background: var(--garnish-primary-green);
      &:before {
        background: url('../../../images/wordgroup-white.svg') center center
          no-repeat;
        height: 28px;
        width: 28px;
      }
      .title {
        color: white;
      }
      .title-field {
        input {
          margin: 0.5rem 0;
          letter-spacing: 0.5px;
          font-family: 'Montserrat', sans-serif;
          color: 'white' !important;
          font-weight: bold;
          font-size: 16px;
          width: 100%;
          line-height: 18px;
          text-transform: uppercase;
          ::placeholder {
            text-transform: uppercase;
          }
        }
      }
    }
  }
  &.garnish-tip {
    border-left: 7px solid #e98c7a;
    .title-bar {
      background: var(--garnish-dark-green);
      padding: 0 12px 0 10px;
      &:before {
        background: url('../../../images/garnish-twist.svg') center center
          no-repeat;
        height: 44px;
        width: 24px;
      }
      .title {
        color: white;
      }
    }
  }
  &.did-you-know {
    .title-bar {
      background: #9acb42;
      &:before {
        background: url('../../../images/did-you-know-green.svg') center center
          no-repeat;
        height: 28px;
        width: 28px;
      }
      .title {
        color: #013831;
      }
    }
  }
  &.closed {
    .body {
      display: none;
    }
  }
}
