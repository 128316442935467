.example-block {
  grid-column: 1 / 4;
  margin: 10px 0;
  text-align: center;
  color: #60c2a7;
  .title-bar {
    .title {
      margin: 0;
      letter-spacing: 0.5px;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
  .body {
    padding: 0 20px;
    font-family: 'Roboto Condensed', sans-serif;
    p {
      font-size: 14px;
      line-height: 18px;
      font-style: italic;
      margin: 5px 0;
    }
  }
}
