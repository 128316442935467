@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

.preview-content {
  font-family: 'Roboto Condensed', sans-serif;
  color: #333;
  font-size: 16px;
  display: grid;
  grid-template-columns: 20px auto 20px;
  grid-template-rows: auto;
  grid-auto-flow: row;
  // row-gap: 20px;
  &__wrapper {
    position: relative;
    min-height: 100%;
  }
  &__footer {
    background: var(--garnish-dark-green);
    position: -webkit-sticky;
    position: sticky;
    will-change: transform;
    bottom: 0px;
    left: 0px;
    width: 100%;
    nav {
      padding: 0 20px;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-icon {
      max-height: 26px;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
  }
  &__header {
    background: var(--garnish-dark-green);
    h1,
    h2 {
      text-align: center;
      margin: 0;
      font-size: 16px;
      line-height: 19px;
    }
    h1 {
      color: white;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 16px;
    }
    h2 {
      padding: 8px 20px;
      background: var(--dark-grey);
      color: #4d4d4d;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: normal;
    }
    nav {
      padding: 0 5px;
      height: 38px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-icon {
      max-height: 24px;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
  }
  & > * {
    grid-column: 2 / 3;
    &:first-child {
      padding-top: 20px;
    }
  }
  @import 'modules/alignment';
  @import 'modules/paragraphs';
  @import 'modules/headings';
  @import 'modules/lists';
  @import 'modules/blockquotes';
  @import 'modules/divider';
  @import 'modules/expandables';
  @import 'modules/example';
  @import 'modules/visual-media';
}
