// Just a divider.

hr {
  width: 100%;
  grid-column: 1 / 4;
  border: 1px solid #9acb42;
  margin: 2rem 0;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
