.visual-media {
  position: relative;
  max-width: 500px;
  padding: 20px;
  grid-column: 1 / 4;
  color: #d1d1d1;
  .image-container {
    position: relative;
  }
  .image-wrapper {
    text-align: center;
  }
  img {
    margin: 0 auto;
    max-width: 100%;
    display: inline-block;
    &.image-preview {
      opacity: 0.7;
    }
  }
  .image-upload-placeholder {
    position: relative;
    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
  .title {
    margin: 10px 0;
    letter-spacing: 0.5px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
  }
  .caption {
    font-size: 14px;
    line-height: 16px;
    font-style: italic;
    & > p:last-of-type {
      margin-bottom: 0;
    }
  }
  .caption-field {
    padding: 0.5rem 0;
    width: 100%;
    margin: 0.5rem 0;
    color: #d1d1d1;
    input {
      font-family: 'Roboto Condensed', sans-serif !important;
      font-size: 14px;
      font-style: italic;
    }
  }
  .title-field {
    margin: 0.5rem 0;
    width: 100%;
    input {
      text-transform: uppercase;
      line-height: 18px;
      letter-spacing: 0.5px;
      font-family: 'Montserrat', sans-serif;
      color: #d1d1d1;
      font-weight: bold;
      font-size: 16px;
      &::placeholder {
        text-transform: uppercase;
      }
    }
  }
  .edit-bar {
    position: absolute;
    width: 40px;
    height: 60px;
    top: 10px;
    right: 20px;
    display: none;
    z-index: 1;
  }
  .is-selected {
    .edit-bar {
      display: block;
    }
  }
  .no-content {
    opacity: 0.9;
  }
  &.dark {
    color: #c2c2c2;
    background-color: var(--garnish-dark-green);
    .title,
    .title-field {
      color: #9acb42;
    }
    .caption-field {
      color: #c2c2c2;
    }
  }
}

.video-wrapper {
  position: relative;
  padding-top: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
