:root {
  --garnish-dark-green: #033832;
  --garnish-primary-green: #60c2a7;
  --garnish-light-green: #67c1a7;
  --light-grey: #f9fafb;
  --grey: #f6f6f6;
  --dark-grey: #e4e7eb;
  --darker-grey: #9ea0a5;
  --graphite: #66788a;
  font-size: 10px;
}

* {
  box-sizing: border-box;
}

#notistack-snackbar {
  font-size: 1.5rem;
}

body {
  overflow-x: hidden;
}

h1 {
  font-size: 3.2rem;
  text-transform: uppercase;
  font-family: 'Montserrat';
  color: var(--garnish-dark-green);
  letter-spacing: 0.1rem;
  margin-top: 0;
  margin-bottom: 0.8rem;
  text-align: left;
}

h3 {
  text-transform: uppercase;
  color: var(--darker-grey);
  font-weight: normal;
}

h4 {
  color: var(--graphite);
  text-transform: uppercase;
  font-size: 1.2rem;
  margin: 0;
}

.App {
  text-align: center;
  background-color: var(--light-grey);
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
  font-size: 1.4rem;

  section.app-contents {
    display: flex;
    flex: 1;
    min-height: 0;
  }

  /* TODO: this needs adjustment to prevent jankiness */

  .slide-fade-enter {
    opacity: 0;
    transform: translateX(0.5rem);
  }

  .slide-fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 400ms ease, transform 400ms ease;
  }

  .slide-fade-exit {
    opacity: 0;
    transform: translateX(0.5rem);
    position: absolute;
    top: 0;
  }

  .slide-fade-exit-active {
    opacity: 0;
    transform: translateX(0.5rem);
    transition: opacity 400ms ease;
    position: absolute;
    top: 0;
  }
}
