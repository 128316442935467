blockquote {
  font-family: 'Roboto Condensed', sans-serif;
  margin: 0;
  display: block;
  grid-column: 1 / 4;
  background-color: rgba(215, 215, 215, 0.5);
  background-image: url('../../../images/left-double-quote.svg'),
    url('../../../images/right-double-quote.svg');
  background-position: top 7px left 20px, bottom 7px right 20px;
  background-repeat: no-repeat;
  border-left: solid 7px var(--garnish-primary-green);
  font-size: 14px;
  line-height: 18px;
  padding: 40px 20px;
  font-style: italic;
  color: var(--garnish-primary-green);
}
